* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

button {
    background-color: transparent;
    cursor: pointer;
    outline: none;
}

.users-wrapper {
    text-align: center;
    width: 100vw;
    min-height: 100vh;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    background-color: seagreen;
}

.users-table {
    width: 1000px;
    text-align: center;
}

@media screen and (max-width: 378px) {
    .users-table {
        width: 350px;
        text-align: center;
        font-size: 11px;
    }

}
