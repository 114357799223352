* {
    margin: 0;
    padding: 0;
    box-sizing: 0;
}

.form-wrapper {
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: seagreen;
}

.form-wrapper form {
    display: flex;
    justify-content: space-evenly;
    flex-direction: column;
    background-color: antiquewhite;
    padding: 20px;
    border-radius: 20px;
    width: 500px;
    height: 300px;

}